import { PUBLIC_FOLDER } from 'shared/constants'
import { readFileAsArrayBufferAsync } from 'shared/utils/tools/filereader'
import { get, patch, post, del } from 'utils/axios'
import { processRestItem } from '../collection/model'
import { buildCreateItemPayload, buildUpdateItemPayload } from './helpers'
import { data } from 'jquery'

export const createTag = async tag => {
  const url = '/taxonomy/term?_format=json'
  const payload = {
    vid: [
      {
        'target_id': 'tags',
        'target_type': 'taxonomy_vocabulary'
      }
    ],
    name: [
      {
        'value': tag
      }
    ]
  }
  const res = await post(url, payload, { withCredentials: true }, { csrfToken: true })
  return res
    ? { 
        tid: res.tid && res.tid[0] && res.tid[0].value,
        name: res.name && res.name[0] && res.name[0].value
      }
    : null
}

export const addTagToItem = (tid, itemId) => {
  const url = '/node/item/tag'
  const payload = {
    'node_id': itemId,
    'term_id': tid
  }
  return post(url, payload)
}

export const removeTagFromItem = (tag, itemId) => {
  const url = `/node/item/tag/${itemId}`
  const payload = { 'term_id': tag.tid }
  return del(url, payload)
}

export const createItem = async formData => {
  let fid
  let thumbnail 
  if (formData.file && formData.file.file) {
    fid = await createFile(formData.file.file)
  }
  if (formData.pdfThumbnail) {
    const result = await fetch(formData.pdfThumbnail);
    const arrayBuffer = await result.arrayBuffer();
    thumbnail = await createFileURLWithData(`thumbnail.png`, arrayBuffer)
  }
  const url = '/node?_format=json'
  const payload = buildCreateItemPayload(formData, fid)
  if (thumbnail) payload['field_new_thumbnail_url'] = [{ "value": thumbnail }]
  const res = await post(url, payload, { withCredentials: true }, { csrfToken: true })
  const processed = processRestItem(res)
  return processed
}

export const createItems = async (dataList, csrfToken) => {
  const fileIds = {};
  const thumbnails = {};
  for (let index = 0; index < dataList.length; index++) {
    const data = dataList[index];
    const { uuid } = data;
    if (data.data) {
      const result = await fetch(data.data);
      const arrayBuffer = await result.arrayBuffer();
      const fid = await createFileWithData(data.name, arrayBuffer);
      fileIds[uuid] = fid;
    }
    if (data.pdfThumbnail) {
      const result = await fetch(data.pdfThumbnail);
      const arrayBuffer = await result.arrayBuffer();
      const thumbnail = await createFileURLWithData(`thumbnail.png`, arrayBuffer)
      thumbnails[uuid] = thumbnail;
    }
  }
  
  const payload = dataList.map((data, index) => {
    const fid = fileIds[data.uuid];
    const thumbnail = thumbnails[data.uuid];
    const payload = buildCreateItemPayload(data, fid);
    if (thumbnail) payload['field_new_thumbnail_url'] = [{ "value": thumbnail }]
    const body = JSON.stringify(payload);
    const requestId = 'req-' + (index + 1);
    const uri = "/node?_format=json";
    const action = "create";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    };
    return {
      requestId,
      uri,
      action,
      body,
      headers,
    }
  })

  const url = '/subrequests?_format=json';
  const res = await post(url, payload);
  const requests = (res && res['req-1'] && Object.values(res)) || []
  return requests && requests.map(request => processRestItem(JSON.parse(request.body)))
}

export const updateItem = async data => {
  const url = `/node/${data.id}?_format=json`
  const payload = buildUpdateItemPayload(data)
  const res = await patch(url, payload)
  return processRestItem(res)
}

export const addFileToItem = async (id, file) => {
  const url = `/jsonapi/node/item/${id}/field_file`
  const config = {
    headers: {
      'Content-Disposition': `filename="${encodeURI(file.name)}"`,
      'Content-Type': 'application/octet-stream',
      'Accept': 'application/vnd.api+json'
    }
  }
  const fileBinary = await readFileAsArrayBufferAsync(file)
  const res = await post(url, fileBinary, config)
  const fileUrl = res && res.data && res.data.attributes && res.data.attributes.uri.url
  return fileUrl && fileUrl.replace('public://', PUBLIC_FOLDER)
}

export const getTagsByNodeId = async nid => {
  const url = `/node/item/tag/${nid}`
  const res = await get(url)
  return res && res.map(item => ({ tid: item.id, name: item.name, addedByPartner: item['field_added_by_partner'] }))
}

export const getItemById = async id => {
  const url = `/node/${id}?_format=json`
  const res = await get(url)
  return processRestItem(res)
}

export const createFile = async file => {
  const url = '/file/upload/node/item/field_file?_format=json'
  const config = {
    headers: {
      'Content-Disposition': `filename="${encodeURI(file.name)}"`,
      'Content-Type': 'application/octet-stream',
      'Accept': 'application/vnd.api+json',
    },
    withCredentials: true
  }
  const fileBinary = await readFileAsArrayBufferAsync(file)
  const res = await post(url, fileBinary, config, { csrfToken: true })
  return res && res.fid && res.fid[0] && res.fid[0].value
} 

export const createFileWithData = async (name, data) => {
  const url = '/file/upload/node/item/field_file?_format=json'
  const config = {
    headers: {
      'Content-Disposition': `filename="${encodeURI(name)}"`,
      'Content-Type': 'application/octet-stream',
      'Accept': 'application/vnd.api+json'
    },
    withCredentials: true,
  }
  const res = await post(url, data, config, { csrfToken: true })
  return res && res.fid && res.fid[0] && res.fid[0].value
}

export const createFileURLWithData = async (name, data) => {
  const url = '/file/upload/node/item/field_file?_format=json'
  const config = {
    headers: {
      'Content-Disposition': `filename="${encodeURI(name)}"`,
      'Content-Type': 'application/octet-stream',
      'Accept': 'application/vnd.api+json'
    },
    withCredentials: true
  }
  const res = await post(url, data, config, { csrfToken: true })
  return res && res.uri && res.uri[0] && res.uri[0].url;
}